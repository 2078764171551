import {
  convertValue,
  getDecimalPoint,
  MEASUREMENT_KEYS,
} from "../unit-conversion";

const mapPassViewColumns = (inputColumns, reportColumns) => {
  //   let inputColumns = [
  //     {
  //       title: "Current (A)",
  //       key: "currentA",
  //       position: 8,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["amps", "low"],
  //           key: "amps_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["amps", "avg"],
  //           key: "amps_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["amps", "high"],
  //           key: "amps_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Volt (V)",
  //       key: "voltV",
  //       position: 9,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["volts", "low"],
  //           key: "volts_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["volts", "avg"],
  //           key: "volts_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["volts", "high"],
  //           key: "volts_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Travel Speed (in/min)",
  //       key: "travelSpeed",
  //       position: 10,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["travel", "low"],
  //           key: "travel_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["travel", "avg"],
  //           key: "travel_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["travel", "high"],
  //           key: "travel_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "True Energy (KJ/in)",
  //       key: "trueEnergy",
  //       position: 11,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["heat", "low"],
  //           key: "heat_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["heat", "avg"],
  //           key: "heat_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["heat", "high"],
  //           key: "heat_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Heat (KJ/in)",
  //       key: "heat",
  //       position: 12,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["calculated", "low"],
  //           key: "calculated_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["calculated", "avg"],
  //           key: "calculated_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["calculated", "high"],
  //           key: "calculated_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Wire Speed (in/min)",
  //       key: "wireSpeed",
  //       position: 13,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["wire", "low"],
  //           key: "wire_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["wire", "avg"],
  //           key: "wire_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["wire", "high"],
  //           key: "wire_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Oscillation Width (in)",
  //       key: "oscillationWidth",
  //       position: 14,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["oscillate", "low"],
  //           key: "oscillate_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["oscillate", "avg"],
  //           key: "oscillate_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["oscillate", "high"],
  //           key: "oscillate_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Frequency (cyc/min)",
  //       key: "frequency",
  //       position: 15,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["frequency", "low"],
  //           key: "frequency_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["frequency", "avg"],
  //           key: "frequency_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["frequency", "high"],
  //           key: "frequency_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Target (A)",
  //       key: "target",
  //       position: 16,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["target", "low"],
  //           key: "target_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["target", "avg"],
  //           key: "target_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["target", "high"],
  //           key: "target_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Horizontal Bias (A)",
  //       key: "horizontalBias",
  //       position: 17,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["horizontal", "low"],
  //           key: "horizontal_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["horizontal", "avg"],
  //           key: "horizontal_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["horizontal", "high"],
  //           key: "horizontal_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //     {
  //       title: "Total Wire Consumed (in)",
  //       key: "totalWireConsumed",
  //       position: 18,
  //       children: [
  //         {
  //           title: "Low",
  //           dataIndex: ["total", "low"],
  //           key: "total_low",
  //           width: 20,
  //         },
  //         {
  //           title: "Avg",
  //           dataIndex: ["total", "avg"],
  //           key: "total_avg",
  //           width: 20,
  //         },
  //         {
  //           title: "High",
  //           dataIndex: ["total", "high"],
  //           key: "total_high",
  //           width: 20,
  //         },
  //       ],
  //     },
  //   ];

  //   let reportColumns = {
  //     switch: "All",
  //     params: ["Arc Current", "Arc Volts", "Travel Speed", "Wire Speed", "Heat"],
  //     units: {
  //       ArcCurrent: "A",
  //       ArcVolts: "V",
  //       "Travel Speed": "in/min",
  //       "True Energy": "kJ/in",
  //       Heat: "kJ/in",
  //       "Wire Speed": "in/min",
  //       "Oscillation Width": "in",
  //       Frequency: "cyc/min",
  //       Target: "A",
  //       "Horizontal Bias": "A",
  //       "Total Wire Consumed": "in",
  //       "Band Diameter": "in",
  //       "Wire Diameter": "in",
  //       "Logging Distance": "in",
  //       Distance: "in",
  //     },
  //   };

  const columnsMapping = {
    CURRENT_A: "currentA",
    VOLT_V: "voltV",
    TRAVEL_SPEED: "travelSpeed",
    TRUE_ENERGY: "trueEnergy",
    HEAT: "heat",
    WIRE_SPEED: "wireSpeed",
    OSCILLATION_WIDTH: "oscillationWidth",
    FREQUENCY: "frequency",
    TARGET: "target",
    HORIZONTAL_BIAS: "horizontalBias",
    TOTAL_WIRE_CONSUMED: "totalWireConsumed",
  };

  return inputColumns?.map((ite) => {
    let changeKeys = [
      columnsMapping.CURRENT_A,
      columnsMapping.VOLT_V,
      columnsMapping.TRAVEL_SPEED,
      columnsMapping.TRUE_ENERGY,
      columnsMapping.HEAT,
      columnsMapping.WIRE_SPEED,
      columnsMapping.OSCILLATION_WIDTH,
      columnsMapping.FREQUENCY,
      columnsMapping.TARGET,
      columnsMapping.HORIZONTAL_BIAS,
      columnsMapping.TOTAL_WIRE_CONSUMED,
    ];

    if (changeKeys.includes(ite.key)) {
      switch (ite.key) {
        case columnsMapping.CURRENT_A: {
          return {
            ...ite,
            title: `Current (${reportColumns.units.ArcCurrent || "A"})`,
          };
        }
        case columnsMapping.VOLT_V: {
          return {
            ...ite,
            title: `Volt (${reportColumns.units.ArcVolts || "V"})`,
          };
        }

        case columnsMapping.TRAVEL_SPEED: {
          return {
            ...ite,
            title: `Travel Speed (${
              reportColumns.units["Travel Speed"] || "in/min"
            })`,
          };
        }

        case columnsMapping.TRUE_ENERGY: {
          return {
            ...ite,
            title: `True Energy (${
              reportColumns.units["True Energy"] || "KJ/in"
            })`,
          };
        }

        case columnsMapping.HEAT: {
          return {
            ...ite,
            title: `Heat (${reportColumns.units.Heat || "KJ/in"})`,
          };
        }

        case columnsMapping.WIRE_SPEED: {
          return {
            ...ite,
            title: `Wire Speed (${
              reportColumns.units["Wire Speed"] || "in/min"
            })`,
          };
        }

        case columnsMapping.OSCILLATION_WIDTH: {
          return {
            ...ite,
            title: `Oscillation Width (${
              reportColumns.units["Oscillation Width"] || "in"
            })`,
          };
        }

        case columnsMapping.FREQUENCY: {
          return {
            ...ite,
            title: `Frequency (${reportColumns.units.Frequency || "cyc/min"})`,
          };
        }

        case columnsMapping.TARGET: {
          return {
            ...ite,
            title: `Target (${reportColumns.units.Target || "A"})`,
          };
        }

        case columnsMapping.HORIZONTAL_BIAS: {
          return {
            ...ite,
            title: `Horizontal Bias (${
              reportColumns.units["Horizontal Bias"] || "A"
            })`,
          };
        }

        case columnsMapping.TOTAL_WIRE_CONSUMED: {
          return {
            ...ite,
            title: `Total Wire Consumed (${
              reportColumns.units["Total Wire Consumed"] || "in"
            })`,
          };
        }
      }
    } else {
      return ite;
    }
  });
};

const mapperForUnitConversionPassView = (sampleInputObject, reportColumns) => {
  //   let sampleInputObject = {
  //     type: "Lead",
  //     status: "false",
  //     stationNumbers: "Station 3",
  //     logging_distance: "0.248",
  //     bug_number: "001",
  //     lead_wire_diameter: "0.040",
  //     pipe_diameter: "10.0",
  //     job_number: "RUYA 10 CMT",
  //     trail_wire_diameter: "0.040",
  //     gps: '{"Latitude":"","Longitude":"","Confidence":"","HDOP":""}',
  //     iwm: '{"Logging_period":"","Trav_gain":"","Wall_thickness":""}',
  //     band_diameter: "12.1",
  //     start_time: "1731945.713",
  //     end_time: "1731945.771",
  //     station_number: "3",
  //     buyType: "CCW",
  //     weldStartTime: "Nov-18, 21:31:53",
  //     s_deviceid_event: "004700283550501320313442_77422",
  //     weld_time: "0.97",
  //     name: "Fill 1",
  //     travel: {
  //       avg: "16.88",
  //       low: "14.40",
  //       high: "18.10",
  //     },
  //     amps: {
  //       avg: "187.38",
  //       low: "178.50",
  //       high: "193.90",
  //     },
  //     volts: {
  //       avg: "21.56",
  //       low: "19.56",
  //       high: "23.56",
  //     },
  //     wire: {
  //       avg: "329.98",
  //       low: "318.90",
  //       high: "342.10",
  //     },
  //     frequency: {
  //       avg: "178.20",
  //       low: "160.00",
  //       high: "190.00",
  //     },
  //     oscillate: {
  //       avg: "0.13",
  //       low: "0.13",
  //       high: "0.14",
  //     },
  //     target: {
  //       avg: "21.48",
  //       low: "21.00",
  //       high: "22.00",
  //     },
  //     horizontal: {
  //       avg: "0.00",
  //       low: "0.00",
  //       high: "0.00",
  //     },
  //     total: {
  //       avg: "0.00",
  //       low: "0.00",
  //       high: "0.00",
  //     },
  //     heat: {
  //       avg: "35.90",
  //       low: "15.50",
  //       high: "1187.50",
  //     },
  //     calculated: {
  //       avg: "14.46",
  //       low: "12.43",
  //       high: "17.12",
  //     },
  //     sl_no: 2,
  //   };

  //   let reportColumns = {
  //     switch: "All",
  //     params: ["Arc Current", "Arc Volts", "Travel Speed", "Wire Speed", "Heat"],
  //     units: {
  //       ArcCurrent: "A",
  //       ArcVolts: "V",
  //       "Travel Speed": "in/min",
  //       "True Energy": "kJ/in",
  //       Heat: "kJ/in",
  //       "Wire Speed": "in/min",
  //       "Oscillation Width": "in",
  //       Frequency: "cyc/min",
  //       Target: "A",
  //       "Horizontal Bias": "A",
  //       "Total Wire Consumed": "in",
  //       "Band Diameter": "in",
  //       "Wire Diameter": "in",
  //       "Logging Distance": "in",
  //       Distance: "in",
  //     },
  //   };

  sampleInputObject.amps.avg = convertValue(
    sampleInputObject.amps.avg,
    reportColumns.units.ArcCurrent,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.AMPS)
  );
  sampleInputObject.amps.low = convertValue(
    sampleInputObject.amps.low,
    reportColumns.units.ArcCurrent,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.AMPS)
  );
  sampleInputObject.amps.high = convertValue(
    sampleInputObject.amps.high,
    reportColumns.units.ArcCurrent,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.AMPS)
  );

  sampleInputObject.volts.avg = convertValue(
    sampleInputObject.volts.avg,
    reportColumns.units.ArcVolts,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.VOLTS)
  );
  sampleInputObject.volts.low = convertValue(
    sampleInputObject.volts.low,
    reportColumns.units.ArcVolts,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.VOLTS)
  );
  sampleInputObject.volts.high = convertValue(
    sampleInputObject.volts.high,
    reportColumns.units.ArcVolts,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.VOLTS)
  );

  sampleInputObject.travel.avg = convertValue(
    sampleInputObject.travel.avg,
    reportColumns.units["Travel Speed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TRAVEL_SPEED)
  );
  sampleInputObject.travel.low = convertValue(
    sampleInputObject.travel.low,
    reportColumns.units["Travel Speed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TRAVEL_SPEED)
  );
  sampleInputObject.travel.high = convertValue(
    sampleInputObject.travel.high,
    reportColumns.units["Travel Speed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TRAVEL_SPEED)
  );

  sampleInputObject.calculated.avg = convertValue(
    sampleInputObject.calculated.avg,
    reportColumns.units.Heat,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.CALCULATED_HEAT)
  );
  sampleInputObject.calculated.low = convertValue(
    sampleInputObject.calculated.low,
    reportColumns.units.Heat,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.CALCULATED_HEAT)
  );
  sampleInputObject.calculated.high = convertValue(
    sampleInputObject.calculated.high,
    reportColumns.units.Heat,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.CALCULATED_HEAT)
  );

  sampleInputObject.heat.avg = convertValue(
    sampleInputObject.heat.avg,
    reportColumns.units["True Energy"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TRUE_ENERGY)
  );
  sampleInputObject.heat.low = convertValue(
    sampleInputObject.heat.low,
    reportColumns.units["True Energy"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TRUE_ENERGY)
  );
  sampleInputObject.heat.high = convertValue(
    sampleInputObject.heat.high,
    reportColumns.units["True Energy"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TRUE_ENERGY)
  );

  sampleInputObject.wire.avg = convertValue(
    sampleInputObject.wire.avg,
    reportColumns.units["Wire Speed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.WIRE_SPEED)
  );

  sampleInputObject.wire.low = convertValue(
    sampleInputObject.wire.low,
    reportColumns.units["Wire Speed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.WIRE_SPEED)
  );
  sampleInputObject.wire.high = convertValue(
    sampleInputObject.wire.high,
    reportColumns.units["Wire Speed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.WIRE_SPEED)
  );

  sampleInputObject.oscillate.avg = convertValue(
    sampleInputObject.oscillate.avg,
    reportColumns.units["Oscillation Width"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.OSCILLATE_WIDTH)
  );
  sampleInputObject.oscillate.low = convertValue(
    sampleInputObject.oscillate.low,
    reportColumns.units["Oscillation Width"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.OSCILLATE_WIDTH)
  );
  sampleInputObject.oscillate.high = convertValue(
    sampleInputObject.oscillate.high,
    reportColumns.units["Oscillation Width"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.OSCILLATE_WIDTH)
  );

  sampleInputObject.frequency.avg = convertValue(
    sampleInputObject.frequency.avg,
    reportColumns.units.Frequency,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.FREQUENCY)
  );
  sampleInputObject.frequency.low = convertValue(
    sampleInputObject.frequency.low,
    reportColumns.units.Frequency,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.FREQUENCY)
  );
  sampleInputObject.frequency.high = convertValue(
    sampleInputObject.frequency.high,
    reportColumns.units.Frequency,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.FREQUENCY)
  );

  sampleInputObject.target.avg = convertValue(
    sampleInputObject.target.avg,
    reportColumns.units.Target,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TARGET)
  );
  sampleInputObject.target.low = convertValue(
    sampleInputObject.target.low,
    reportColumns.units.Target,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TARGET)
  );
  sampleInputObject.target.high = convertValue(
    sampleInputObject.target.high,
    reportColumns.units.Target,
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TARGET)
  );

  sampleInputObject.horizontal.avg = convertValue(
    sampleInputObject.horizontal.avg,
    reportColumns.units["Horizontal Bias"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.HORIZONTAL_BIAS)
  );
  sampleInputObject.horizontal.low = convertValue(
    sampleInputObject.horizontal.low,
    reportColumns.units["Horizontal Bias"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.HORIZONTAL_BIAS)
  );
  sampleInputObject.horizontal.high = convertValue(
    sampleInputObject.horizontal.high,
    reportColumns.units["Horizontal Bias"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.HORIZONTAL_BIAS)
  );

  sampleInputObject.total.avg = convertValue(
    sampleInputObject.total.avg,
    reportColumns.units["Total Wire Consumed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TOTAL_WIRE_CONSUMED)
  );
  sampleInputObject.total.low = convertValue(
    sampleInputObject.total.low,
    reportColumns.units["Total Wire Consumed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TOTAL_WIRE_CONSUMED)
  );
  sampleInputObject.total.high = convertValue(
    sampleInputObject.total.high,
    reportColumns.units["Total Wire Consumed"],
    false,
    getDecimalPoint(MEASUREMENT_KEYS.TOTAL_WIRE_CONSUMED)
  );

  return sampleInputObject;
};

export const passViewHelper = {
  mapperForUnitConversionPassView,
  mapPassViewColumns,
};
