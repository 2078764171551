import { Checkbox, Select } from "antd";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { tlogGraphHelperData } from "../../helpers";
import { tlogGraphHelper } from "../../helpers/tlog/tlog-graph";
import { convertValue } from "../../helpers/unit-conversion";
import CommonLoader from "../Loaders/loader";

const { Option } = Select;

const IWMGraph = ({
  tlogParsedData,
  loader,
  reportColumns,
  tableLimitData,
}) => {
  const reducerList = useSelector((state) => state);
  const themeState = reducerList?.switchThemeReducer?.switchTheme;
  const selectedProjectNumber = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_number
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const separatedDataByZone = {
    head1: tlogParsedData.filter((item) => item.zone === "Head 1"),
    head2: tlogParsedData.filter((item) => item.zone === "Head 2"),
    head3: tlogParsedData.filter((item) => item.zone === "Head 3"),
    head4: tlogParsedData.filter((item) => item.zone === "Head 4"),
  };

  const activeHeads = Object.keys(separatedDataByZone).filter(
    (key) => separatedDataByZone[key].length > 0
  );
  const numberOfActiveHeads = activeHeads.length;

  const [selectedOption, setSelectedOption] = useState("Chart 1");

  const [showHead1, setShowHead1] = useState(true);
  const [showHead2, setShowHead2] = useState(true);
  const [showHead3, setShowHead3] = useState(true);
  const [showHead4, setShowHead4] = useState(true);

  let colorOptions = [
    {
      value: "Travel Speed (in/min)",
      label: "Travel Speed (in/min)",
      color: "#e03540",
      key: "travelSpeed",
    },
    {
      value: "Volts (v)",
      key: "volt",
      label: "Volts (v)",
      color: "#FFA500",
    },
    {
      value: "Current (A)",
      key: "current",
      label: "Current (A)",
      color: "#ee82ee",
    },
    {
      value: "Heat (kJ/in)",
      label: "Heat (kJ/in)",
      color: "#008000",
      key: "heat",
    },
  ];

  colorOptions = tlogGraphHelper.mapTlogGraphColumns(
    colorOptions,
    reportColumns
  );

  // const cleanData = (dataArray) =>
  //   dataArray.filter((value) => value !== "" && !isNaN(value));
  const cleanData = (dataArray) =>
    dataArray
      .map((value) => {
        const sanitizedValue = value.replace(/-color/g, "");
        return parseFloat(sanitizedValue);
      })
      .filter((value) => !isNaN(value));

  const time =
    separatedDataByZone?.head1.map((item) => item.time.slice(3)) || [];

  const returnTime = (headType) => {
    return (
      separatedDataByZone?.[headType].map((item) => item.time.slice(3)) || []
    );
  };

  const returnTravelSpeed = (headType) => {
    return cleanData(
      separatedDataByZone?.[headType].map((item) => item.travelSpeed) || []
    );
  };

  const returnVolt = (headType) => {
    return cleanData(
      separatedDataByZone?.[headType].map((item) => item.volts) || []
    );
  };

  const returnCurrent = (headType) => {
    return cleanData(
      separatedDataByZone?.[headType].map((item) => item.current) || []
    );
  };

  const returnHeat = (headType) => {
    return cleanData(
      separatedDataByZone?.[headType].map((item) => item.heat) || []
    );
  };

  const empty = [];

  const getMinMaxValues = (minKey, maxKey, unit) => {
    let realUnit = tlogGraphHelper.mapTlogGraphUnits(unit, reportColumns);
    return {
      min: convertValue(parseFloat(tableLimitData[0]?.[minKey]) || 0, realUnit),
      max: convertValue(parseFloat(tableLimitData[0]?.[maxKey]) || 0, realUnit),
    };
  };

  const getGraphData1 = ({ headNumber, headType, selectedOption }) => {
    let yAxisColor;
    let yAxisTitle;
    let minValue = 0;
    let maxValue = 0;

    switch (selectedOption) {
      case "Travel Speed (in/min)":
        yAxisColor = "#e03540";
        yAxisTitle = `Head ${headNumber} : ${
          colorOptions.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.TRAVEL_SPEED
          )?.label
        }`;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          "travel_speed_min",
          "travel_speed_max",
          tlogGraphHelper.columnsMapping.TRAVEL_SPEED
        ));
        return tlogGraphHelperData.IWMGraph(
          themeState,
          returnTravelSpeed(headType),
          returnTime(headType),
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Volts (v)":
        yAxisColor = "#FFA500";
        yAxisTitle = `Head ${headNumber} : ${
          colorOptions.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.VOLT_V
          )?.label
        }`;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          "volts_min",
          "volts_max",
          tlogGraphHelper.columnsMapping.VOLT_V
        ));
        return tlogGraphHelperData.IWMGraph(
          themeState,
          returnVolt(headType),
          returnTime(headType),
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Current (A)":
        yAxisColor = "#ee82ee";
        yAxisTitle = `Head ${headNumber} : ${
          colorOptions.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.CURRENT_A
          )?.label
        }`;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          "current_min",
          "current_max",
          tlogGraphHelper.columnsMapping.CURRENT_A
        ));
        return tlogGraphHelperData.IWMGraph(
          themeState,
          returnCurrent(headType),
          returnTime(headType),
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Heat (kJ/in)":
        yAxisColor = "#008000";
        yAxisTitle = `Head ${headNumber} : ${
          colorOptions.find(
            (ite) => ite.key == tlogGraphHelper.columnsMapping.HEAT
          )?.label
        }`;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          "calculated_heat_min",
          "calculated_heat_max",
          tlogGraphHelper.columnsMapping.HEAT
        ));
        return tlogGraphHelperData.IWMGraph(
          themeState,
          returnHeat(headType),
          returnTime(headType),
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      default:
        yAxisColor = "#0000FF";
        yAxisTitle = "Chart 1";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          empty,
          time,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );
    }
  };

  const [FirstGraph, setFirstGraph] = useState(
    getGraphData1({
      headNumber: 1,
      headType: "head1",
      selectedOption: selectedOption,
    })
  );
  const [SecondGraph, setSecondGraph] = useState(
    getGraphData1({
      headNumber: 2,
      headType: "head2",
      selectedOption: selectedOption,
    })
  );
  const [ThirdGraph, setThirdGraph] = useState(
    getGraphData1({
      headNumber: 3,
      headType: "head3",
      selectedOption: selectedOption,
    })
  );
  const [forthGraph, setforthGraph] = useState(
    getGraphData1({
      headNumber: 4,
      headType: "head4",
      selectedOption: selectedOption,
    })
  );

  useEffect(() => {
    setFirstGraph(
      getGraphData1({
        headNumber: 1,
        headType: "head1",
        selectedOption: selectedOption,
      })
    );
    setSecondGraph(
      getGraphData1({
        headNumber: 2,
        headType: "head2",
        selectedOption: selectedOption,
      })
    );
    setThirdGraph(
      getGraphData1({
        headNumber: 3,
        headType: "head3",
        selectedOption: selectedOption,
      })
    );
    setforthGraph(
      getGraphData1({
        headNumber: 4,
        headType: "head4",
        selectedOption: selectedOption,
      })
    );
  }, [selectedOption, themeState, tlogParsedData]);

  const isDataEmpty = FirstGraph.series[0].data.length === 0;
  const isDataEmpty2 = SecondGraph.series[0].data.length === 0;
  const isDataEmpty3 = ThirdGraph.series[0].data.length === 0;
  const isDataEmpty4 = forthGraph.series[0].data.length === 0;

  return (
    <>
      {loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          <div className="iwm-header">
            <div className="iwm-selector">
              <Select
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
              >
                {colorOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: option.color,
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ textTransform: "math-auto" }}>
                        {option.label}
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
            {numberOfActiveHeads === 4 && (
              <div className="iwm-checkbox">
                <Checkbox
                  checked={showHead1}
                  onChange={() => setShowHead1(!showHead1)}
                >
                  Head 1
                </Checkbox>
                <Checkbox
                  checked={showHead2}
                  onChange={() => setShowHead2(!showHead2)}
                >
                  Head 2
                </Checkbox>
                <Checkbox
                  checked={showHead3}
                  onChange={() => setShowHead3(!showHead3)}
                >
                  Head 3
                </Checkbox>
                <Checkbox
                  checked={showHead4}
                  onChange={() => setShowHead4(!showHead4)}
                >
                  Head 4
                </Checkbox>
              </div>
            )}
          </div>
          <div className="iwm-graph">
            <div className="single-line-graph">
              {showHead1 && !isDataEmpty && (
                <Chart
                  options={FirstGraph.options}
                  series={FirstGraph.series}
                  type="line"
                  height={300}
                  width={numberOfActiveHeads === 1 ? 1260 : 620}
                  className="Graph-background"
                />
              )}
              {showHead2 && !isDataEmpty2 && (
                <Chart
                  options={SecondGraph.options}
                  series={SecondGraph.series}
                  type="line"
                  height={300}
                  width={numberOfActiveHeads === 1 ? 1260 : 620}
                  className="Graph-background"
                />
              )}
            </div>
            <div className="single-line-graph">
              {showHead3 && !isDataEmpty3 && (
                <Chart
                  options={ThirdGraph.options}
                  series={ThirdGraph.series}
                  type="line"
                  height={300}
                  width={numberOfActiveHeads === 1 ? 1260 : 620}
                  className="Graph-background"
                />
              )}
              {showHead4 && !isDataEmpty4 && (
                <Chart
                  options={forthGraph.options}
                  series={forthGraph.series}
                  type="line"
                  height={300}
                  width={numberOfActiveHeads === 1 ? 1260 : 620}
                  className="Graph-background"
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default IWMGraph;
