import { Select, Table, InputNumber } from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startStatusLoader } from "../../redux/statusCalculation/actions";
import { FETCH_STATUS_CALCULATION_SAGA } from "../../redux/statusCalculation/types";
import CommonLoader from "../Loaders/loader";
import { convertValue } from "../../helpers/unit-conversion";

const { Option } = Select;

const StatusConfiguration = () => {
  const dispatch = useDispatch();
  const StatusData = useSelector((state) => state.statusReducer);
  const tableLimitData = StatusData?.statusList?.allDataSet?.recordList;
  const weldDetails = StatusData?.statusList?.weldDetails;

  console.log(weldDetails, "welddetails");

  const Loader = StatusData?.isStatusLoading;

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const [selectedJobNumber, setSelectedJobNumber] = useState(null);

  // useEffect(() => {
  //   if (tableLimitData) {
  //     transposedData.map((item) => ({
  //       ...item,
  //       checked:
  //         tableLimitData.find((dataItem) => dataItem.id === item.key)
  //           ?.checked || false,
  //     }));
  //   }
  // }, [tableLimitData]);

  useEffect(() => {
    if (selectedProjectId && selectedProjectName) {
      const params = {
        skip: 0,
        limit: 100,
        project_id: selectedProjectId,
        project_name: selectedProjectName,
      };
      dispatch(startStatusLoader());
      dispatch({ type: FETCH_STATUS_CALCULATION_SAGA, payload: params });
      setSelectedJobNumber(null);
    }
  }, [dispatch, selectedProjectId, selectedProjectName]);

  useEffect(() => {
    if (tableLimitData?.length > 0) {
      const uniqueJobNumbers = getUniqueJobNumbers(tableLimitData, weldDetails);
      if (uniqueJobNumbers.length > 0) {
        setSelectedJobNumber(uniqueJobNumbers[0]?.value);
      }
    }
  }, [tableLimitData]);

  // const normalizePassGroupName = (name) => {
  //   const lowerCaseName = name.toLowerCase();

  //   if (lowerCaseName === "hot" || lowerCaseName === "hot pass") {
  //     return "Hot";
  //   }

  //   const normalizedPassGroup = passGroupOrder.find(
  //     (group) => group.toLowerCase() === lowerCaseName
  //   );
  //   return normalizedPassGroup || name;
  // };
  const passGroupOrder = [
    "Root",
    "Hot",
    "Hot Pass",
    "Fill",
    "Last Fill",
    "Strip",
    "Cap",
  ];

  const getRecordList = () => {
    let filteredData = selectedJobNumber
      ? tableLimitData?.filter((item) => item.job_number === selectedJobNumber)
      : tableLimitData;

    let transformedData = filteredData?.reduce((acc, pass) => {
      // const normalizedGroupName = normalizePassGroupName(pass.pass_group_name);
      const existingPass = acc.find(
        // (p) => normalizePassGroupName(p.pass_group_name) === normalizedGroupName
        (p) => p.pass_group_name === pass.pass_group_name
      );

      if (existingPass) {
        existingPass.values.push({ ...pass });
      } else {
        acc.push({
          pass_group_name: pass.pass_group_name,
          values: [{ ...pass }],
        });
      }
      return acc;
    }, []);

    transformedData = transformedData?.sort((a, b) => {
      const indexA = passGroupOrder.indexOf(a.pass_group_name);
      const indexB = passGroupOrder.indexOf(b.pass_group_name);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;
      return a.pass_group_name.localeCompare(b.pass_group_name);
    });

    let finalData = [];
    transformedData = transformedData?.filter(
      (ite) =>
        Boolean(ite.pass_group_name) &&
        !/^\d+$/.test(ite.pass_name) &&
        !/^\d+$/.test(ite.pass_group_name)
    );

    transformedData?.forEach((item) => {
      finalData.push({
        ...item,
        pass_group_name: `${item.pass_group_name} (Min)`,
        original_pass_name: item.pass_group_name,
        dataIndex: "min",
        key: "min",
      });
      finalData.push({
        ...item,
        pass_group_name: `${item.pass_group_name} (Max)`,
        original_pass_name: item.pass_group_name,
        dataIndex: "max",
        key: "max",
      });
    });

    return finalData || [];
  };

  const { projectReducer } = useSelector((state) => state);
  let reportColumns = projectReducer?.selectedProject?.reportColumns;
  reportColumns = JSON.parse(reportColumns);
  const { units } = reportColumns;

  const transposedData = [
    {
      key: "current",
      label: `Current (${units?.ArcCurrent || "A"})`,
      min: "current_min",
      max: "current_max",
    },
    {
      key: "volts",
      label: `Volts (${units?.ArcVolts || "V"})`,
      min: "volts_min",
      max: "volts_max",
    },
    {
      key: "wire_speed",
      label: `Wire Speed (${units?.["Wire Speed"] || "in/min"})`,
      min: "wire_speed_min",
      max: "wire_speed_max",
    },
    {
      key: "travel_speed",
      label: `Travel Speed (${units?.["Travel Speed"] || "in/min"})`,
      min: "travel_speed_min",
      max: "travel_speed_max",
    },
    {
      key: "oscillation",
      label: `Oscillation Width (${units?.["Oscillation Width"] || "in"})`,
      min: "oscillation_width_min",
      max: "oscillation_width_max",
    },
    {
      key: "calculated_heat",
      label: `Heat (${units?.Heat || "KJ/in"})`,
      min: "calculated_heat_min",
      max: "calculated_heat_max",
    },
    {
      key: "heat",
      label: `True Energy (${units?.["True Energy"] || "KJ/in"})`,
      min: "heat_min",
      max: "heat_max",
    },
  ];

  const getValue = (key, type, originalPassName) => {
    if (!tableLimitData || tableLimitData.length === 0) return null;

    // const normalizedPassName = normalizePassGroupName(originalPassName);

    let filteredData = tableLimitData?.filter(
      (ite) =>
        ite.pass_group_name === originalPassName &&
        (selectedJobNumber ? ite.job_number === selectedJobNumber : true)
    );

    let allKeyValues = filteredData
      .filter((item) => item[key])
      .map((item) => Number(item[key]));

    let toUnits = "";
    if (key?.toString()?.includes("current")) {
      toUnits = units?.ArcCurrent || "A";
    } else if (key?.toString()?.includes("volts")) {
      toUnits = units?.ArcVolts || "V";
    } else if (key?.toString()?.includes("wire_speed")) {
      toUnits = units?.["Wire Speed"] || "in/min";
    } else if (key?.toString()?.includes("travel_speed")) {
      toUnits = units?.["Travel Speed"] || "in/min";
    } else if (key?.toString()?.includes("oscillation_width")) {
      toUnits = units?.["Oscillation Width"] || "in";
    } else if (key?.toString()?.includes("calculated_heat")) {
      toUnits = units?.Heat || "kJ/in";
    } else if (key?.toString()?.includes("heat")) {
      toUnits = units?.["True Energy"] || "kJ/in";
    } else {
      toUnits = "";
    }

    if (type === "min") {
      return allKeyValues?.length > 0
        ? convertValue(Math.min(...allKeyValues).toString(), toUnits)
        : "";
    } else if (type === "max") {
      return allKeyValues?.length > 0
        ? convertValue(Math.max(...allKeyValues).toString(), toUnits)
        : "";
    }
    return null;
  };

  const columns = [
    {
      title: "Parameter",
      dataIndex: "label",
      key: "label",
      render: (text) => (
        <div className="status-tb-title">
          <p>{text}</p>
        </div>
      ),
      width: 200,
      fixed: "left",
      className: "rowtitle",
    },

    // eslint-disable-next-line no-unsafe-optional-chaining
    ...getRecordList()?.map((ite) => {
      return {
        title: ite?.pass_group_name,
        dataIndex: ite?.dataIndex,
        key: ite?.key,
        width: 150,
        render: (text, record) => {
          return (
            <InputNumber
              className="no-arrows"
              style={{ width: "20%", minWidth: "120px", maxWidth: "150px" }}
              value={getValue(
                record[ite.key].toString(),
                ite?.key,
                ite?.original_pass_name
              )}
              onChange={(value) => handleInputChange(value, record.key, text)}
              disabled={true}
            />
          );
        },
      };
    }),
  ];

  const getUniqueJobNumbers = (data, wd) => {
    const jobNumbers = data?.map((item) => item.job_number);
    console.log(jobNumbers, "jobNumbers");
    let list = [...new Set(jobNumbers)]
      .filter((jobNumber) => jobNumber !== null)
      ?.map((job_number) => {
        let welding_system =
          wd?.find((wld) => wld.job_number == job_number)?.welding_system || "";

        return {
          label: `${welding_system}: ${job_number}`,
          value: job_number,
        };
      });

    console.log(list, "list - job");

    return list;
  };

  const hasJobNumbers = tableLimitData?.some((item) => item.job_number);
  return (
    <>
      {Loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <div className="status-cofig-table">
          {tableLimitData && tableLimitData.length > 0 && hasJobNumbers ? (
            <>
              <div className="status-table-limit">
                <span className="select-job-no">Select Job Number:</span>
                <Select
                  className="job-number-selector"
                  placeholder="Select job number"
                  style={{ width: 200 }}
                  value={selectedJobNumber}
                  onChange={(value) => setSelectedJobNumber(value)}
                >
                  {getUniqueJobNumbers(tableLimitData, weldDetails).map(
                    (jobNumber) => (
                      <Option key={jobNumber.value} value={jobNumber.value}>
                        {jobNumber.label}
                      </Option>
                    )
                  )}
                </Select>
                <Table
                  columns={columns}
                  dataSource={transposedData}
                  pagination={false}
                  scroll={{ x: 800 }}
                  rowKey="key"
                />
                <div className="input-select-flex">
                  <div className="slop-time">
                    <span className="slop-span">
                      Slope Time (sec):
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In{" "}
                    </span>
                    <InputNumber
                      value={
                        weldDetails?.find(
                          (ite) => ite.job_number === selectedJobNumber
                        )?.startSkipTime || 0
                      }
                      className="slop-input"
                      disabled={true}
                    />
                    <span className="slop-span"> Out </span>
                    <InputNumber
                      value={
                        weldDetails?.find(
                          (ite) => ite.job_number === selectedJobNumber
                        )?.stopSkipTime || 0
                      }
                      className="slop-input"
                      disabled={true}
                    />
                  </div>
                  <div className="slop-time">
                    <span className="slop-span">
                      Status Calculation Method:{" "}
                    </span>
                    <Select
                      defaultValue="Instantaneous"
                      className="status-selector"
                      disabled={true}
                    >
                      <Option value="Instantaneous">Instantaneous</Option>
                      <Option value="Average">Average</Option>
                    </Select>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="slog-NoData">
              <p>
                Status configuration details are not available for this project.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StatusConfiguration;
