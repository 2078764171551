const UNITS = {
  IN_MIN: "in/min",
  IN: "in",
  CM: "cm",
  MM: "mm",
  CM_MIN: "cm/min",
  MM_MIN: "mm/min",
  LB: "lb",
  KG: "kg",
  KJ_IN: "kJ/in",
  KJ_CM: "kJ/cm",
  KJ_MM: "kJ/mm",
};

export const reportColumnEnums = {
  ArcCurrent: "ArcCurrent",
  ArcVolts: "ArcVolts",
  TravelSpeed: "Travel Speed",
  TrueEnergy: "True Energy",
  Heat: "Heat",
  WireSpeed: "Wire Speed",
  OscillationWidth: "Oscillation Width",
  Frequency: "Frequency",
  Target: "Target",
  HorizontalBias: "Horizontal Bias",
  TotalWireConsumed: "Total Wire Consumed",
  BandDiameter: "Band Diameter",
  WireDiameter: "Wire Diameter",
  LoggingDistance: "Logging Distance",
  Distance: "Distance",
};

export const convertValue = (
  value,
  toUnits = "",
  isFromTLogScreen = false,
  decimalPoints = null
) => {
  let isColorIncluded = value?.toString()?.includes("-color");
  value = value?.toString()?.includes("-color")
    ? value?.toString().replace("-color", "")
    : value;

  const parsedValue = parseFloat(value);
  let convertedValue = value;

  if (!isNaN(parsedValue)) {
    switch (toUnits) {
      // case UNITS.IN_MIN:
      //   convertedValue
      //   break;
      // case UNITS.IN:
      //   convertedValue = parsedValue / 2.54;
      //   break;
      case UNITS.CM:
        convertedValue = parsedValue * 2.54;
        break;
      case UNITS.MM:
        convertedValue = parsedValue * 25.4;
        break;
      case UNITS.CM_MIN:
        convertedValue = parsedValue * 2.54;
        break;
      case UNITS.MM_MIN:
        convertedValue = parsedValue * 25.4;
        break;
      case UNITS.LB:
        convertedValue = parsedValue * 2.205;
        break;
      case UNITS.KG:
        convertedValue = parsedValue / 2.205;
        break;
      // case UNITS.KJ_IN:
      //   convertedValue = parsedValue * 2.54;
      //   break;
      case UNITS.KJ_CM:
        convertedValue = parsedValue / 2.54;
        break;
      case UNITS.KJ_MM:
        convertedValue = parsedValue / 25.4;
        break;
      default:
        break;
    }

    if (!Number.isInteger(convertedValue)) {
      convertedValue = isFromTLogScreen
        ? convertedValue
        : parseFloat(convertedValue).toFixed(
            decimalPoints == 0 || decimalPoints ? decimalPoints : 2
          );
    }

    return `${convertedValue}${isColorIncluded ? "-color" : ""}`;
  }

  return value;
};

export const MEASUREMENT_KEYS = {
  AMPS: "AMPS",
  FREQUENCY: "FREQUENCY",
  TRAVEL_SPEED: "TRAVEL_SPEED",
  WIRE_SPEED: "WIRE_SPEED",

  VOLTS: "VOLTS",
  DISTANCE: "DISTANCE",

  HEAT: "HEAT",
  CALCULATED_HEAT: "CALCULATED_HEAT",
  OSCILLATE_WIDTH: "OSCILLATE_WIDTH",

  TRUE_ENERGY: "TRUE_ENERGY",
  TARGET: "TARGET",
  HORIZONTAL_BIAS: "HORIZONTAL_BIAS",
  TOTAL_WIRE_CONSUMED: "TOTAL_WIRE_CONSUMED",
};

export const getDecimalPoint = (key) => {
  switch (key) {
    case MEASUREMENT_KEYS.AMPS:
    case MEASUREMENT_KEYS.FREQUENCY:
    case MEASUREMENT_KEYS.TRAVEL_SPEED:
    case MEASUREMENT_KEYS.WIRE_SPEED:
      return 0;

    case MEASUREMENT_KEYS.VOLTS:
      return 1;

    case MEASUREMENT_KEYS.HEAT:
    case MEASUREMENT_KEYS.CALCULATED_HEAT:
    case MEASUREMENT_KEYS.OSCILLATE_WIDTH:
      return 2;

    case MEASUREMENT_KEYS.DISTANCE:
      return 3;

    default:
      return 2;
  }
};
